import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts/postLayout.js";
import PostImage from "../src/components/posts/postImage";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Introduction`}</h2>
    <p>{`Hey folks! It's always tough starting from a blank page (or in this case, blog) so we can consider this a bit of a "Hello World" post.`}</p>
    <p>{`As long as we're here, for those that know me, this picture will make a lot of sense:`}</p>
    <PostImage image="about-me.jpg" alt="Picture of me eating a pretzel at Disney" mdxType="PostImage" />
    <p>{`Not because I'm an aspiring Disney model/blogger, but I do thoroughly enjoy trips there, snacks, and will indulge in the occasional corny photo =). I also really enjoy technology, both from the software side as well as "gadgets" and other things that make my life more complicated, so I imagine a lot of the content here will end up revolving around that. Selfishly, this will also serve as a bit of self-documentation as well. Regardless, this blog for me is as much about messing around with some technologies (shout out to `}<a parentName="p" {...{
        "href": "https://material-ui.com/"
      }}>{`Material UI`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{`) as it is about carving out a small place on the Internet to call my own. Hopefully anyone that lands here can find something useful as well!`}</p>
    <h2>{`Reach Out!`}</h2>
    <p>{`If you have any feedback or comments, or anything in particular you'd like to see, feel free to follow and/or reach out to me on `}<a parentName="p" {...{
        "href": "https://twitter.com/c_r_weber"
      }}>{`Twitter`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      